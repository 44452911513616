import { EpubCFI } from 'epubjs';
import type { Note, NoteComment, NoteCommentGroup, NotesGroup } from '../types/book';

/**
 * Gets the start and end points of a CFI
 * @param cfi The CFI to get endpoints for
 * @returns An array with two elements: [start, end]
 */
const getEndpoints = (cfi: EpubCFI): [EpubCFI, EpubCFI] => {
  if (cfi.range) {
    const start = cfi;
    const end = new EpubCFI(cfi.toString());
    start.collapse(true);
    end.collapse(false);
    return [start, end];
  }
  return [cfi, cfi];
};

/**
 * Checks if two CFIs overlap
 * @param cfi1 The first CFI (can be a range or a point)
 * @param cfi2 The second CFI (can be a range or a point)
 * @returns True if the CFIs overlap, false otherwise
 */
const cfiOverlap = (cfi1: string, cfi2: string): boolean => {
  const epubCfi1 = new EpubCFI(cfi1);
  const epubCfi2 = new EpubCFI(cfi2);

  const [start1, end1] = getEndpoints(epubCfi1);
  const [start2, end2] = getEndpoints(epubCfi2);

  return !(EpubCFI.prototype.compare(end1, start2) < 0 || EpubCFI.prototype.compare(start1, end2) > 0);
};

/**
 * Filters a list of CFIs to only those that overlap with a target CFI
 * @param targetCFI The CFI to check for overlap
 * @param cfiList The list of CFIs to filter
 * @returns An array of CFIs that overlap with the target CFI
 */
const filterOverlappingCFIs = (targetCFI: string, cfiList: string[]): string[] => {
  return cfiList.filter((cfi) => cfiOverlap(targetCFI, cfi));
};

/**
 * Gets all notes that overlap with a target CFI
 * @param targetCFI The CFI to check for overlap
 * @param notes The group of notes to check
 * @returns An object containing overlapping notes, with note IDs as keys
 */
const getNotesEnclosingCFI = (targetCFI: string, notes: NotesGroup): NotesGroup => {
  return Object.fromEntries(Object.entries(notes).filter(([id, note]) => cfiOverlap(targetCFI, note.cfiRange)));
};

/**
 * Filters out deleted items from a group of notes or note comments.
 *
 * @template T - The type of the group (NotesGroup or NoteCommentGroup)
 * @param group - The group of notes or note comments to filter.
 * @returns A new object of the same type as the input, containing only the non-deleted items.
 */
const filterDeleted = <T extends NotesGroup | NoteCommentGroup>(group: T | undefined): T => {
  if (!group) {
    return {} as T;
  }

  return Object.fromEntries(Object.entries(group).filter(([_, item]) => !item.deleted)) as T;
};

/**
 * Counts the number of non-deleted comments for a given note.
 *
 * @param note - The note object containing comments to count.
 * @returns The number of non-deleted comments in the note.
 */
const countComments = (note: Note): number => {
  return Object.keys(filterDeleted(note.comments)).length;
};

/**
 * Gets the first non-deleted comment on a note, or undefined if there are none.
 *
 * @param note - The note object containing comments to search.
 * @returns The first non-deleted comment, or undefined if there are no non-deleted comments.
 */
const getFirstComment = (note: Note): NoteComment | undefined => {
  const nonDeletedComments = filterDeleted(note.comments);
  const firstCommentKey = Object.keys(nonDeletedComments)[0];
  return firstCommentKey ? nonDeletedComments[firstCommentKey] : undefined;
};

export { getNotesEnclosingCFI, filterDeleted, countComments, getFirstComment };
