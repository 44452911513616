import React, { Component, type CSSProperties } from 'react';
import Epub, { Book, EpubCFI } from 'epubjs';
import { type NavItem, Contents, type Rendition, type Location } from 'epubjs';
import type { RenditionOptions } from 'epubjs/types/rendition';
import type { BookOptions } from 'epubjs/types/book';
import type { BookSettings, BookMeta, UserInfo, NotesGroup, NoteCommentGroup, Prefs, Note } from '../types/book';
import { saveLocations, loadLocations, updateBook, getTheme } from '../utils/books';
import ContextMenu from './ContextMenu'; // Adjust the path according to your file structure
import { defaultStyles } from '../style';
import { googleFonts, generateGoogleFontsUrl } from '../fonts.config';
import { afterLayout } from '../utils/core';
import { locationSize } from '../config';
import { CircularProgress } from '@mui/material';
import { getNoteStatus, setNoteStatus } from '../utils/books';
import Loading from './Loading';

export type RenditionOptionsFix = RenditionOptions & {
  allowPopups: boolean;
};

export type IToc = {
  label: string;
  href: string;
};

interface IEpubViewStyle {
  viewHolder: CSSProperties;
  view: CSSProperties;
}

export const EpubViewStyle: IEpubViewStyle = {
  viewHolder: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  view: {
    height: '100%',
  },
};

interface TimelineItem {
  location: Location;
  timestamp: number;
}

function debounce(func: any, wait: any) {
  let timeout: any;
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export type IEpubViewProps = {
  url: string | ArrayBuffer;
  epubInitOptions?: Partial<BookOptions>;
  epubOptions?: Partial<RenditionOptionsFix>;
  epubViewStyles?: IEpubViewStyle;
  loadingView?: React.ReactNode;
  location?: string | number | null;
  handleOnRelocated?(newLocation: Location, oldLocation?: Location, targetLocation?: string): void;
  showToc?: boolean;
  tocChanged?(value: NavItem[]): void;
  getRendition?(rendition: Rendition): void;
  handleKeyPress?(): void;
  handleClick?(): void;
  textSelected?(cfiRange: string, contents: Contents): void;
  settings?: BookSettings;
  iframeRef?: HTMLElement;
  iframe?: Document;
  meta?: BookMeta;
  user?: UserInfo | null;
  updateAllNotes?: (a: NotesGroup) => void;
  addNote?(cfiRange: string, color: string, text: string, comments?: NoteCommentGroup): void;
  toggleStatusBar?: () => void;
  id: string;
  onMarkClicked?: (cfirange: EpubCFI, data: any, contents: Contents) => void;
  onDisplayed?: () => void;
  onRendered?: () => void;
  onScrolled?: () => void;
  applySettings?: (rendition: Rendition) => Promise<void>;
  applyAnnotations?: (rendition: Rendition, notes: NotesGroup) => Promise<void>;
  toggleDrawer?: (withTab?: number) => void;
  applyImageInversion?: () => void;
  redrawAnnotations?: () => void;
  setGeneratingLocations?: (generating: boolean) => void;
  generatingLocations?: boolean;
  prefs?: Prefs;
  ignoreLocationChangeRef: React.MutableRefObject<boolean>;
  onResize?: () => void;
  doSettings?: () => void;
  setIsInitialized?: (isInitialized: boolean) => void;
  isNoteBoxOpen?: boolean;
  ignoreNextTapRef: React.MutableRefObject<boolean>;
};

type IEpubViewState = {
  isLoaded: boolean;
  toc: NavItem[];
  contextMenu: { visible: boolean; x: number; y: number };
  selectText: string;
  selectContext: string;
  selectRange: string;
  selection: Selection | null;
  touchCount?: number;
  touchTimeout?: NodeJS.Timeout | number | null;
};

export class EpubView extends Component<IEpubViewProps, IEpubViewState> {
  state: Readonly<IEpubViewState> = {
    isLoaded: false,
    toc: [],
    contextMenu: { visible: false, x: 0, y: 0 },
    selectText: '',
    selectContext: '',
    selectRange: '',
    selection: null,
    touchCount: 0,
    touchTimeout: null,
  };
  iframeRef?: HTMLElement;
  iframe?: Document;
  viewerRef = React.createRef<HTMLDivElement>();
  location?: string | number | null;
  book?: Book;
  rendition?: Rendition;
  touchStartLoc?: number[];
  isInitialized?: boolean;
  selecting: boolean;
  updateAllNotes?: () => void;
  id: string;
  tries: number;
  handleOnRelocated?: (newLocation: Location, oldLocation?: Location) => void;
  //   onTouchEnd?: (e: TouchEvent) => void
  //   nav?: Navigation;
  locationToReturn?: Location;
  targetLocation?: string;
  lastLocation?: Location;
  bogus?: boolean;
  oldLocation?: number;
  swipe?: boolean;
  selectionTimeout?: any;
  listening?: boolean;
  pageNumber?: number;
  settings?: BookSettings;
  applyImageInversion?: () => void;
  setGeneratingLocations?: (generating: boolean) => void;
  generatingLocations?: boolean;
  prefs?: Prefs;

  constructor(props: IEpubViewProps) {
    super(props);
    this.book = this.rendition = undefined;
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onRelocated = this.onRelocated.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.state = {
      ...this.state, // Keep existing state
      contextMenu: { visible: false, x: 0, y: 0 },
      selectText: '',
      selectContext: '',
      selectRange: '',
      selection: null,
    };
    this.id = props.id;
    this.handleSelectColor = this.handleSelectColor.bind(this);
    this.handleSelection = debounce(this.handleSelection.bind(this), 50);
    this.handleTextSelection = debounce(this.handleTextSelection.bind(this), 100);
    this.getIframeDoc = this.getIframeDoc.bind(this);
    this.getSelect = this.getSelect.bind(this);
    this.handleSelectionAdjustment = this.handleSelectionAdjustment.bind(this);
    this.selecting = false;
    this.tries = 2;
    this.getSurroundingText = this.getSurroundingText.bind(this);
    this.getSurroundingSentences = this.getSurroundingSentences.bind(this);
  }

  componentWillMount(): void {
    (window as any)['epub'] = this;
  }

  componentDidMount() {
    this.initBook();
    document.addEventListener('keyup', this.handleKeyPress, false);
    this.rendition?.hooks.content.register((contents: Contents) => {
      this.iframe = contents.document;
      this.iframeRef = this.iframe.documentElement;
      this.iframe.addEventListener('mousedown', this.handleSelection.bind(this));
      this.iframe.addEventListener('mouseup', this.handleSelection.bind(this));
      this.iframe.addEventListener('touchend', this.handleSelection.bind(this));
      this.iframe.addEventListener('touchstart', this.handleSelection.bind(this));
      this.iframe.addEventListener('selectionchange', this.handleSelection.bind(this));
    });
  }

  componentWillUnmount() {
    if (this.book) {
      this.book.destroy();
    }
    this.book = this.rendition = undefined;
    document.removeEventListener('keyup', this.handleKeyPress, false);
  }

  shouldComponentUpdate(nextProps: IEpubViewProps, nextState: IEpubViewState) {
    return (
      !this.state.isLoaded ||
      nextProps.location !== this.props.location ||
      nextProps.url !== this.props.url ||
      nextProps.isNoteBoxOpen !== this.props.isNoteBoxOpen ||
      nextState.contextMenu.visible !== this.state.contextMenu.visible ||
      nextState.contextMenu.x !== this.state.contextMenu.x ||
      nextState.contextMenu.y !== this.state.contextMenu.y ||
      nextState.selection !== this.state.selection ||
      nextState.selectRange !== this.state.selectRange ||
      nextState.selectText !== this.state.selectText ||
      nextState.selectContext !== this.state.selectContext ||
      nextProps.settings?.lastColor !== this.props.settings?.lastColor
    );
  }

  componentDidUpdate(prevProps: IEpubViewProps) {
    if (
      this.props.location &&
      prevProps.location !== this.props.location &&
      typeof this.props.location === 'string' &&
      (!this.props.location.includes('epubcfi') || this.props.location.includes(','))
    ) {
      console.debug('Updating location in EpubView:', this.props.location);
      this.display(this.props.location);
    }
    //   if (prevProps.url !== this.props.url) {
    //     this.initBook();
    //   }
  }

  timeline: TimelineItem[] = [];
  get currentLocation() {
    return this.timeline[0]?.location;
  }

  revealHiddenNotes = (startCfi: string, endCfi: string): Note | null => {
    const notes = this.props.meta?.notes || {};
    let revealedNote: Note | null = null;

    Object.values(notes).forEach((note) => {
      const noteStatus = getNoteStatus(note.id);
      if (noteStatus?.hidden) {
        const noteCfi = EpubCFI.prototype.parse(note.cfiRange);
        if (
          (!startCfi ||
            !EpubCFI.prototype.isCfiString(startCfi) ||
            EpubCFI.prototype.compare(startCfi, noteCfi) <= 0) &&
          EpubCFI.prototype.compare(noteCfi, endCfi) <= 0
        ) {
          setNoteStatus(note.id, { read: Object.keys(note.comments || {}).length === 0, hidden: false });
          if (!revealedNote) {
            revealedNote = note;
          }
        }
      }
    });

    if (revealedNote) {
      this.props.redrawAnnotations?.();
    }

    return revealedNote;
  };

  handleScrolledReveal = () => {
    if (this.rendition && this.props.settings?.flow === 'scrolled') {
      const visibleRange = this.rendition.location;
      if (visibleRange) {
        const startCfi = visibleRange.start.cfi;
        const endCfi = visibleRange.end.cfi;
        const revealedNote = this.revealHiddenNotes('', startCfi);
        if (revealedNote) {
          this.snapToHighlight(revealedNote.cfiRange);
        }
      }
    }
  };

  snapToHighlight = (cfiRange: string) => {
    if (this.rendition) {
      const range = this.rendition.getRange(cfiRange, 'epubjs-ignore');
      if (range) {
        const rect = range.getBoundingClientRect();
        const iframeDoc = this.getIframeDoc();
        if (!iframeDoc) return;
        const iframeOffset = iframeDoc.defaultView?.frameElement?.getBoundingClientRect().y || 0;
        const scrollTop = rect.top + iframeOffset;

        // Find the relevant G element
        // const gElement = range.startContainer.parentElement?.closest('g');
        const gElement = document.querySelector(`g[data-cfi-range="${cfiRange}"]`) as HTMLElement;
        if (gElement) {
          // Apply initial animation properties
          gElement.style.opacity = '0';
          gElement.style.transform = 'translateY(500px)';

          // Apply final animation properties
          afterLayout(() => {
            gElement.style.transition = 'all 2s ease-in-out';
            gElement.style.transform = 'translateY(0)';
            gElement.style.opacity = '1';
          });
          //   range.startContainer.parentElement?.scrollIntoView({
          //     behavior: 'smooth',
          //     block: 'start',
          //     inline: 'nearest',
          //   });
          const epubContainer = document.querySelector('.epub-container');
          console.log('adjusting by', scrollTop);
          epubContainer?.scrollBy({
            top: Math.round(scrollTop - 150),
            behavior: 'smooth',
          });
          console.log('gElement ', gElement, ' found for cfiRange', cfiRange);
        }

        if (!gElement) {
          console.warn('no gElement found for cfiRange', cfiRange);
        }
      }
    }
  };

  display(target?: string | number | null | undefined, returnable = true) {
    console.debug(
      'display called with ',
      target,
      ' and lastLocation = ',
      this.lastLocation,
      ' and props = ',
      this.props
    );
    if (!this.rendition) {
      return;
    }

    if (target === undefined) {
      console.debug('displayed called with undefined...');
      return;
    }

    if (target === null) {
      if (this.props.meta?.settings?.location) {
        this.rendition.display(this.props.meta.settings.location);
      } else {
        this.rendition.display();
        console.warn('display called with null, but no location found in settings');
      }
    } else if (typeof target === 'string') {
      this.rendition.display(target);
    } else {
      this.rendition.display(target);
    }
  }

  clearSelection = () => {
    (this.rendition?.getContents() as any)[0]?.window.getSelection()?.removeAllRanges();
  };

  selectByCfi = (cfi: string) => {
    const s = this.getIframeDoc()?.getSelection();
    if (s && this.rendition) {
      s.removeAllRanges();
      s.addRange(this.rendition.getRange(cfi, 'epubjs-ignore'));
    }
  };

  handleTextSelection() {
    console.debug('(via handleTextSelection)');
    this.handleSelection();
  }

  adjustSelectionToWhitespace = (range: Range, doc: Document): Range => {
    // const textContent = doc.body.innerText || "";
    const startOffset = range.startOffset;
    const endOffset = range.endOffset;
    const startNodeText = (range.startContainer as HTMLElement).innerText;
    const endNodeText = (range.endContainer as HTMLElement).innerText;

    if (!startNodeText || !endNodeText) {
      console.debug('no text to adjust to whitespace, range=', range, ' doc=', doc);
      return range;
    }

    let newStart = startOffset;
    let newEnd = endOffset;

    // Expand the start to the nearest whitespace
    while (newStart > 0 && !/\s/.test(startNodeText[newStart - 1])) {
      newStart--;
    }
    // Expand the end to the nearest whitespace
    while (newEnd < endNodeText.length && !/\s/.test(endNodeText[newEnd])) {
      newEnd++;
    }

    // Adjust the range
    range.setStart(range.startContainer, newStart);
    range.setEnd(range.endContainer, newEnd);
    return range;
  };

  handleSelectionAdjustment = () => {
    const selection = this.getSelect();
    if (!selection?.rangeCount) return;

    // selection.modify("extend", "left", "word");
    // selection.modify("extend", "right", "word");
    console.debug('adjusted', selection);
    let range = selection.getRangeAt(0);
    range = this.adjustSelectionToWhitespace(range, document);

    // Manually update the selection here, if needed
  };

  getIframeDoc = (): Document | undefined => {
    const currentContents = (this.rendition?.getContents() as any)?.[0];
    if (currentContents) {
      const iframeDoc = currentContents.document;
      return iframeDoc;
    }
  };

  getSelect = (): Selection | undefined => {
    const iframeDoc = this.getIframeDoc();
    if (iframeDoc) {
      const selection = iframeDoc.getSelection();
      if (!selection || selection.isCollapsed || selection.type !== 'Range') {
        return;
      }
      return selection;
    }
  };
  refocusElement(element: HTMLElement) {
    if (!element) return;

    // Blur then focus the element to trigger UI updates
    element.blur();
    element.focus();
  }

  getSurroundingText(selection: Selection | null, preLength = 800, postLength = 200) {
    if (!selection) {
      console.warn('No selection found');
      return '';
    }
    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer as HTMLElement;

    // Get the full text content of the container
    const textContent = container.textContent;
    if (!textContent) {
      console.warn('no text content for container and range', container, range);
      selection.modify('extend', 'backwards', 'line');
      selection.modify('extend', 'forwards', 'line');
      return selection.toString();
    }

    // Find the position of the selected text
    const selectedText = selection.toString();
    const startIndex = textContent.indexOf(selectedText);
    const endIndex = startIndex + selectedText.length;

    // Get 50 characters before and after the selected text
    const surroundingText = textContent.slice(
      Math.max(startIndex - preLength, 0),
      Math.min(endIndex + postLength, textContent.length)
    );

    return surroundingText;
  }

  getPreSelection = (selection: Selection, body: HTMLElement): string | undefined => {
    if (selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    const preSelectionRange = range.cloneRange();
    preSelectionRange.selectNodeContents(body);
    preSelectionRange.setEnd(range.startContainer, range.startOffset);

    return preSelectionRange.toString();
  };

  getPostSelection = (selection: Selection, body: HTMLElement): string | undefined => {
    if (selection.rangeCount === 0) return;

    const range = selection.getRangeAt(0);
    const postSelectionRange = range.cloneRange();
    postSelectionRange.selectNodeContents(body);
    postSelectionRange.setStart(range.endContainer, range.endOffset);

    return postSelectionRange.toString();
  };

  getSurroundingSentences(
    selection: Selection | null,
    // minPreChars = 800,
    // minPostChars = 200,
    maxPreChars = 1600,
    maxPostChars = 400
  ) {
    if (!selection) {
      console.debug('No selection found');
      return '';
    }

    const body = selection.anchorNode?.ownerDocument?.body;
    if (!body) {
      console.error('No body element found');
      return '';
    }
    const selectedText = selection.toString();
    const preText = this.getPreSelection(selection, body) || '';
    const postText = this.getPostSelection(selection, body) || '';

    // Define a regex to match sentences
    // const sentenceRegex = /(?<=[.!?])\s*[^a-z]*?(?=[A-Z](?!\.))|(?=$)/g;

    // const sentenceRegex = /\s+/g;

    // Function to get sentences from text
    const getSentences = (text: string, limit: number = 5000, isPreContext: boolean) => {
      // This regex looks for sentence endings followed by spaces and capital letters
      const sentenceRegex = /[.!?](?=\s+[0-9a-zA-Z])/g;
      let sentences = '';
      let matches = Array.from(text.matchAll(sentenceRegex));

      const processMatches = (start: number, end: number, step: number) => {
        for (let i = start; isPreContext ? i > end : i < end; i += step) {
          let sentenceStart = isPreContext
            ? i > 0
              ? matches[i - 1].index! + matches[i - 1][0].length
              : 0
            : i === 0
            ? 0
            : matches[i - 1].index + matches[i - 1][0].length;
          let sentenceEnd = matches[i].index + matches[i][0].length;
          let sentence = text.slice(sentenceStart, sentenceEnd);

          if (sentences.length + sentence.length > limit) {
            if (sentences.length < limit) {
              sentences = isPreContext
                ? sentence.slice(-(limit - sentences.length)) + sentences
                : sentences + sentence.slice(0, limit - sentences.length);
            }
            break;
          }
          sentences = isPreContext ? sentence + sentences : sentences + sentence;
          if (sentences.length >= limit) break;
        }
      };

      processMatches(isPreContext ? matches.length - 1 : 0, isPreContext ? -1 : matches.length, isPreContext ? -1 : 1);

      // For preContext, add any remaining text from the last match to the selection
      if (isPreContext && matches.length > 0) {
        const lastMatchIndex = matches[matches.length - 1].index! + matches[matches.length - 1][0].length;
        const remainingText = text.slice(lastMatchIndex);
        sentences += remainingText;
      }

      return sentences;
    };

    // Get pre-selection sentences
    const preContext = getSentences(preText, maxPreChars, true);

    // Get post-selection sentences
    const postContext = getSentences(postText, maxPostChars, false);

    // Combine pre-context, selection, and post-context
    return preContext + selectedText + postContext;
  }

  handleSelection = () => {
    if (this.props.isNoteBoxOpen) {
      return;
    }
    const currentContents = (this.rendition?.getContents() as any)?.[0];
    if (currentContents) {
      const iframeDoc = currentContents.document;
      const selection = iframeDoc.getSelection();
      if (!selection || selection.isCollapsed || selection.type !== 'Range') {
        // console.debug("no selection, turning OFF context menu", selection);
        this.setState({
          contextMenu: { visible: false, x: 0, y: 0 },
          selection: null,
          selectText: '',
          selectContext: '',
          selectRange: '',
        });
        return;
      }

      if (this.selecting && /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        // If selecting is in progress, do not process the selection further
        // console.debug("still selecting, ignoring handleSelection");
        return;
      }

      // Debugging handle dragging
      //   console.debug("selection change detected", selection);

      let range = selection.getRangeAt(0);
      //   range = this.adjustSelectionToWhitespace(range, iframeDoc);

      const rect = range.getBoundingClientRect();
      const iframeOffset = iframeDoc.defaultView.frameElement.getBoundingClientRect();
      const x = rect.left + iframeOffset.x;
      let y = rect.top + iframeOffset.y - 160;
      if (y < 0) {
        y = rect.bottom + 50 + iframeOffset.y;
        if (y > iframeOffset.height - 100) {
          y = Math.floor(iframeOffset.height / 2 - 50) + iframeOffset.y;
        }
      }

      const selectText = selection.toString();
      const selectRange = currentContents.cfiFromRange(range);
      //   const paragraph = range.startContainer.parentNode;
      const selectContext = this.getSurroundingText(selection);

      this.setState({
        contextMenu: {
          visible: true,
          x: x,
          y: y,
        },
        selectRange: selectRange,
        selectText: selectText,
        selection: selection,
        selectContext: selectContext,
      });
    }
  };

  handleSelectColor(color: string, text: string, cfiRange: string): void {
    // const selection = this.state.selectText;
    console.debug('Annotation:', text, 'with color', color, 'at range', cfiRange);

    if (this.state.selection && this.rendition) {
      try {
        const cfiRange = this.state.selectRange;
        const text = this.state.selectText;
        console.debug('applying highlight', cfiRange);
        if (cfiRange && this.props.addNote) {
          this.props.addNote(cfiRange, color, text);
        }
      } catch (error) {
        console.error('Error applying highlight:', error);
      }
    }
    console.debug('turning OFF context menu from selectColor');
    this.setState({ contextMenu: { visible: false, x: 0, y: 0 } });
    this.clearSelection();
  }

  getX = () => {
    const currentContents = (this.rendition?.getContents() as any)?.[0];
    if (currentContents) {
      const iframeDoc = currentContents.document;
      const iframeOffset = iframeDoc.defaultView.frameElement.getBoundingClientRect();
      return iframeOffset.x;
    }
    return 0;
  };

  getY = () => {
    const currentContents = (this.rendition?.getContents() as any)?.[0];
    if (currentContents) {
      const iframeDoc = currentContents.document;
      const iframeOffset = iframeDoc.defaultView.frameElement.getBoundingClientRect();
      return iframeOffset.y;
    }
    return 0;
  };

  currentPage = () => {
    const currentContents = (this.rendition?.getContents() as any)?.[0];
    if (currentContents) {
      const iframeView = currentContents.document.defaultView;
      const iframeOffset = iframeView.frameElement.getBoundingClientRect();
      const currentX = -iframeOffset.x;
      const pageWidth = iframeView.outerWidth;
      const totalWidth = iframeView.frameElement.clientWidth;
      return [Math.round(currentX / pageWidth) + 1, Math.round(totalWidth / pageWidth)];
    }
    return [-1, -1];
  };

  currentChapterProgress = () => {
    if (this.props.settings?.flow === 'scrolled') {
      const currentContents = (this.rendition?.getContents() as any)?.[0];
      if (currentContents) {
        const iframeView = currentContents.document.defaultView;
        const iframeOffset = iframeView.frameElement.getBoundingClientRect();
        const currentY = -iframeOffset.y;
        const pageHeight = iframeView.outerHeight;
        const pageWidth = iframeView.outerWidth;
        const totalHeight = iframeView.frameElement.clientHeight;
        if (totalHeight > pageHeight) {
          return currentY / (totalHeight - pageHeight);
        }
        // this is necessary for short pages in scroll flow
        // if (totalHeight < pageHeight) {
        //   console.log('overriding frame height from ', totalHeight, ' to ', pageHeight);
        //   iframeView.frameElement.style.height = pageHeight + 'px';
        //   // Find any img elements with max-height: 0 and remove that style attribute
        //   console.log('current doc = ', currentContents.document);
        //   const images = currentContents.document.querySelectorAll('svg,img');
        //   images.forEach((img: any) => {
        //     if (img.clientHeight == 0) {
        //       console.debug('removing max-height and max-width from ', img);
        //       img.setAttribute(
        //         'style',
        //         `max-width: ${pageWidth}px !important; max-height: ${pageHeight}px !important;`
        //       );
        //     } else {
        //       console.log('no maxHeight ', img);
        //     }
        //   });
        //   const divs = currentContents.document.querySelectorAll('div');
        //   divs.forEach((div: HTMLDivElement) => {
        //     if (div.clientHeight < pageHeight) {
        //       console.debug('Setting dimensions for zero-height div:', div);
        //       div.style.setProperty('width', `auto`, 'important');
        //       div.style.setProperty('height', `auto`, 'important');
        //     }
        //   });
        // }
      }
    } else if (this.props.settings?.flow === 'paginated') {
      const [page, pages] = this.currentPage();
      return pages ? page / pages : 0;
    }
  };

  // if there *is* no next or previous page, open the ToC
  hereBeDragonsWrapper(fn: () => any, direction?: 'next' | 'prev') {
    const initialLocation = [this.rendition?.location.start.cfi, this.rendition?.location.end.cfi];
    document.body.style.transition = 'opacity 0.25s ease-in-out';
    document.body.style.opacity = '0';
    setTimeout(() => {
      document.body.style.transition = '';
      fn().then(() => {
        afterLayout(() => {
          const doc = this.getIframeDoc();
          const theme = getTheme();
          if (doc && theme && !doc.body.classList.contains(theme)) {
            doc.body.classList.remove('light', 'dark');
            doc.body.classList.add(theme);
          }
          this.props.doSettings?.();
          this.props.redrawAnnotations?.();
        });
      });
      setTimeout(() => {
        if (direction && this.props.settings?.flow === 'scrolled') {
          const el = document.querySelector('.epub-container');
          if (el instanceof HTMLDivElement) {
            if (direction === 'next') {
              // scroll to top
              el?.scrollTo({ top: 0, behavior: 'instant' });
            } else if (direction === 'prev') {
              // scroll to bottom
              el?.scrollTo({ top: 1000000, behavior: 'instant' });
            }
          }
        } else if (this.props.settings?.flow === 'paginated') {
          this.display(null);
        }
        document.body.style.transition = 'opacity 1.25s ease-in-out';
        document.body.style.opacity = '1';
        setTimeout(() => {
          document.body.style.transition = '';
          const newLocation = [this.rendition?.location.start.cfi, this.rendition?.location.end.cfi];
          if (initialLocation === newLocation) {
            this.props.toggleDrawer?.(2);
          }
        }, 1500);
      }, 1250);
    }, 250);
  }

  registerEvents() {
    const { onMarkClicked, onDisplayed, onResize, onRendered, onScrolled } = this.props;
    if (this.rendition) {
      this.rendition.on('relocated', this.onRelocated);
      this.rendition.on('selected', this.handleTextSelection);
      //   this.rendition.on('click', this.handleClick)
      this.rendition.on('touchstart', this.onTouchStart);
      this.rendition.on('touchend', this.onTouchEnd);
      //   this.rendition.on("touchmove", this.onTouchMove);
      if (onDisplayed) {
        this.rendition.on('displayed', onDisplayed);
      }
      this.rendition.on('resize', onResize);
      if (onRendered) {
        this.rendition.on('rendered', onRendered);
      }
      this.rendition.on('rendered', () => {
        const theme = getTheme(this.props.user?.prefs?.theme);
        const doc = this.getIframeDoc();
        console.log('themedoc', doc, theme);
        if (theme && doc && !doc.body.classList.contains(theme)) {
          doc.body.classList.remove('light', 'dark');
          doc.body.classList.add(theme);
          this.props.applyImageInversion?.();
        }
        (this.rendition as any).manager?.on?.('scrolled', this.handleScrolledReveal);
      });
      if (onMarkClicked) {
        this.rendition.on('markClicked', (epubcfi: EpubCFI, data: any, contents: any) => {
          this.touchStartLoc = undefined;
          onMarkClicked(epubcfi, data, contents);
        });
        console.log('registered markClicked');
      }
      this.rendition.hooks.content.register((contents: Contents) => {
        const body = contents.document.body;
        const wrapper = contents.document.createElement('div');
        wrapper.classList.add('epubjs-ignore');
        wrapper.id = 'epub-content-wrapper';

        // wrapper.style.padding = `0 ${Math.max((this.props.settings?.margin || 0) * 4, 0)}px`; // Adjust the padding as needed

        // Move all child nodes of the body into the wrapper
        while (body.firstChild) {
          wrapper.appendChild(body.firstChild);
        }

        // Append the wrapper to the body
        body.appendChild(wrapper);

        console.debug('content loaded and wrapped');
      });
      //if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      //   if (!isMobile) {
      //     this.rendition.on('click', this.onMouseClick);
      //   }

      //   this.rendition.on("rotate", window.location.reload);
      //   if (screen?.orientation) {
      //     screen.orientation.addEventListener(
      //       "change",
      //       () => window.location.reload
      //     );
      //   }
      //   }
    }
  }

  onMouseDown = (e: MouseEvent) => {
    // e.stopPropagation();
    // e.preventDefault();

    if (!this.selecting) {
      const touch = e;
      console.debug('mouseDown', e);
      this.touchStartLoc = [touch.clientX, touch.clientY, Date.now()];
      this.selecting = true;
    } else {
      //   setTimeout(() => changeFullscreen(getPrefs().fullscreen), 25);
    }
  };

  //   onMouseClick = (e: MouseEvent) => {
  //     console.debug('mouseClick', e);
  //     this.touchStartLoc = [e.clientX, e.clientY, Date.now() - 1];
  //     this.handlePointerUp([e.clientX, e.clientY, Date.now()]);
  //   };
  onMouseUp = (e: MouseEvent) => {
    if (this.selecting) {
      // e.stopPropagation();
      // e.preventDefault();
      this.handlePointerUp([e.clientX, e.clientY, Date.now()], e);
      console.debug('mouseUp', e);
      this.selecting = false;
    }
  };

  onTouchEnd = (e: TouchEvent) => {
    // Clear the timeout if touch ends before 500ms
    // e.stopPropagation();
    this.state.touchTimeout && clearTimeout(this.state.touchTimeout);
    if (this.selecting) {
      //   if (this.state.touchCount === 2 && this.touchStartLoc && Date.now() - this.touchStartLoc[2] < 200) {
      //     changeFullscreen(!document.fullscreenElement);
      //   }
      if (this.state.touchCount === 3 || this.state.touchCount === 2) {
        this.setState({ touchCount: 0, touchTimeout: null });
      }

      const touchEnd = e.changedTouches[0];
      //   console.debug("touchEnd", e);
      this.handlePointerUp([touchEnd.clientX, touchEnd.clientY, Date.now()], e);
      this.selecting = false;
    }
    // setTimeout(() => changeFullscreen(getPrefs().fullscreen), 25);
  };

  onTouchStart = (e: TouchEvent) => {
    // e.stopPropagation();
    const touch = e.targetTouches[0];
    this.touchStartLoc = [touch.clientX, touch.clientY, Date.now()];
    this.selecting = true;

    // Check for 3-finger tap
    if (e.touches.length === 1) {
      this.state.touchTimeout && clearTimeout(this.state.touchTimeout);
      this.setState({
        touchCount: 1,
        touchTimeout: null,
      });
    } else if (e.touches.length === 3) {
      this.state.touchTimeout && clearTimeout(this.state.touchTimeout);
      this.setState({
        touchCount: 3,
        touchTimeout: setTimeout(() => {
          window.location.reload();
        }, 500),
      });
    } else if (e.touches.length === 2) {
      this.state.touchTimeout && clearTimeout(this.state.touchTimeout);
      this.setState({
        touchCount: 2,
        touchTimeout: setTimeout(() => {
          this.props.toggleDrawer?.();
        }, 250),
      });
    }
    // console.debug("touchStart: ", e);
  };

  handlePointerUp = (touchEndLoc: [number, number, number], e?: TouchEvent | MouseEvent) => {
    if (this.props.ignoreNextTapRef?.current) {
      setTimeout(() => {
        this.props.ignoreNextTapRef.current = false;
        // console.log('setting ignoreNextTapRef to false');
      }, 50);
      //   console.log('ignoring tap in handlePointerUp');
      //   e?.stopPropagation?.();
      return;
    }
    // console.debug("touchEnd", touchEndLoc);
    if (!this.touchStartLoc) return; // Ensure touchStartLoc is set

    // Calculate the differences
    const deltaX = touchEndLoc[0] - this.touchStartLoc[0];
    const deltaY = touchEndLoc[1] - this.touchStartLoc[1];
    const deltaT = touchEndLoc[2] - this.touchStartLoc[2];

    const touchedInnerElement = this.getIframeDoc()?.elementFromPoint(touchEndLoc[0], touchEndLoc[1]);
    console.debug('touchedInnerElement', touchedInnerElement);
    if (touchedInnerElement?.classList?.contains('inline-note')) {
      e?.stopPropagation?.();
      console.debug('touched inline note');
      return;
    }
    // const touchedOuterElement = this.getIframeDoc()?.elementFromPoint(touchEndLoc[0], touchEndLoc[1]);
    // console.debug('touchedOuterElement', touchedOuterElement);
    // if (touchedOuterElement instanceof SVGElement) {
    //   return;
    // }

    const scrolled = this.props.settings?.flow === 'scrolled';

    // console.debug("touchEnd", e, this.touchStartLoc,touchEndLoc)

    // Thresholds
    const swipeLength = Math.abs(deltaX);
    const swipeHeight = Math.abs(deltaY);
    const timeThreshold = 500; // ms, adjust as needed for "long press"
    const swipeThreshold = 50; // pixels, adjust as needed for "minimum swipe length"
    const topThreshold = 200; // tapping above here toggles status bar
    const width = window.innerWidth;
    const scrollThreshold = width / 2;

    if (!this.state.contextMenu.visible) {
      if (scrolled && swipeLength > scrollThreshold) {
        if (deltaX < 0) {
          this.hereBeDragonsWrapper(() => this.rendition?.next(), 'next');
        } else {
          this.hereBeDragonsWrapper(() => this.rendition?.prev(), 'prev');
        }
        return;
      }

      if (!scrolled && deltaT < timeThreshold && swipeLength > swipeThreshold && swipeHeight / swipeLength < 2) {
        if (deltaX > 0) {
          this.swipe = true;
          this.prevPage?.();
        } else {
          this.swipe = true;
          this.nextPage?.();
        }
        return;
      }

      if (!scrolled && this.viewerRef.current && deltaT < 250) {
        const w = this.viewerRef.current.clientWidth;
        const x = touchEndLoc[0] % w;
        const threshold = 0.25;
        const side = w * threshold;

        if (x < side) {
          this.prevPage?.();
          return;
        } else if (w - x < side) {
          this.nextPage?.();
          return;
        }
      }

      if (scrolled && this.viewerRef.current && deltaT < 250 && swipeHeight < 10) {
        const w = this.viewerRef.current.clientWidth;
        const x = touchEndLoc[0] % w;
        const threshold = 0.25;
        const side = w * threshold;

        if (x < side) {
          this.prevPage?.();
          return;
        } else if (w - x < side) {
          this.nextPage?.();
          return;
        }
      }

      if (
        ((scrolled && //touchEndLoc[1] < topThreshold &&
          swipeHeight < 5 &&
          deltaT < 250) ||
          (!scrolled && deltaT < 250 && swipeHeight < 25)) &&
        //   touchEndLoc[1] < topThreshold &&
        !this.state.touchTimeout
      ) {
        this.props.toggleStatusBar?.();
      }
    }
    this.selecting = false;
    this.handleSelection();

    // Reset touchStartLoc
    this.touchStartLoc = undefined;
  };

  onRelocated = (loc: Location) => {
    const newLocation = loc.start.cfi;
    const newProgress = loc.start.percentage;

    this.oldLocation = undefined;

    const [current, total] = this.currentPage();
    // console.log("i think current and total are", current, total);
    // console.log(
    //   "it thinks current and total are ",
    //   this.rendition?.location.start.displayed.page,
    //   this.rendition?.location.start.displayed.total
    // );
    if (this.rendition && current > 0 && total > 0) {
      this.rendition.location.start.displayed.page = current;
      this.rendition.location.start.displayed.total = total;
    }

    this.props.handleOnRelocated?.(loc, this.lastLocation, this.targetLocation);

    if (this.targetLocation === 'next' || this.targetLocation === 'prev') {
      this.targetLocation = undefined;
      this.swipe = false;
    }

    if (!this.lastLocation) {
      this.lastLocation = loc;
    }

    this.lastLocation = loc;
    this.tries = 0;
    this.location = newLocation;
    this.targetLocation = undefined;
  };

  renderBook() {
    const { epubViewStyles = defaultStyles, settings } = this.props;
    return <div ref={this.viewerRef} style={epubViewStyles.view} />;
  }

  handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowRight' && this.nextPage) {
      this.nextPage();
    }
    if (event.key === 'ArrowLeft' && this.prevPage) {
      this.prevPage();
    }
  };

  prevPage = () => {
    // scrolled
    if (this.props.settings?.flow === 'scrolled') {
      this.oldLocation = this.getY();
      this.targetLocation = 'prev';

      const topY = document.querySelector('.epub-container')?.scrollTop || 0;
      const h = document.body.clientHeight || window.outerHeight || 800;
      if (topY) {
        document.querySelector('.epub-container')?.scrollTo({
          top: Math.round(topY - (Boolean(document.fullscreenElement) ? 0.88 : 0.88) * h),
          behavior: 'smooth',
        });
      } else if (topY === 0) {
        this.hereBeDragonsWrapper(() => this.rendition?.prev(), 'prev');
      }

      // paginated
    } else {
      this.oldLocation = this.getX();
      this.targetLocation = 'prev';

      const leftX = document.querySelector('.epub-container')?.scrollLeft || 0;
      const w = document.body.clientWidth || window.outerWidth || 412;
      if (leftX >= w / 2) {
        document.querySelector('.epub-container')?.scrollTo({
          left: Math.round(Math.ceil((leftX - 1.5 * w) / w) * w),
          behavior: 'smooth',
        });
      } else {
        this.hereBeDragonsWrapper(() => this.rendition?.prev(), 'prev');
      }
    }
  };

  nextPage = () => {
    const epubContainer = document.querySelector('.epub-container');

    // scrolled
    if (this.props.settings?.flow === 'scrolled') {
      this.oldLocation = this.getY();
      this.targetLocation = 'next';
      const topY = document.querySelector('.epub-container')?.scrollTop || 0;
      const h = document.body.clientHeight || window.outerHeight || 800;
      console.debug('topY', topY, h, epubContainer?.scrollHeight);

      // if (Math.ceil(topY) < maxScrollTop) {
      if (Math.ceil(topY + h) < Math.floor(epubContainer?.scrollHeight || 0)) {
        epubContainer?.scrollTo({
          top: Math.round(topY + (Boolean(document.fullscreenElement) ? 0.88 : 0.88) * h),
          behavior: 'smooth',
        });
      } else {
        const currentLocation = this.rendition?.location;
        if (currentLocation) {
          if (this.revealHiddenNotes(currentLocation.start.cfi, currentLocation.end.cfi)) {
            // If notes were revealed, don't turn the page
            console.debug(
              'revealed notes, not turning the page at',
              currentLocation.start.cfi,
              currentLocation.end.cfi
            );
            return;
          }
        }
        this.hereBeDragonsWrapper(() => this.rendition?.next(), 'next');
      }

      //paginated
    } else {
      const currentLocation = this.rendition?.location;
      if (currentLocation) {
        if (this.revealHiddenNotes(currentLocation.start.cfi, currentLocation.end.cfi)) {
          // If notes were revealed, don't turn the page
          console.debug('revealed notes, not turning the page at', currentLocation.start.cfi, currentLocation.end.cfi);
          return;
        }
      }

      this.oldLocation = this.getX();
      this.targetLocation = 'next';

      const leftX = epubContainer?.scrollLeft || 0;
      const w = document.body.clientWidth || window.outerWidth || 412;
      if (leftX < (epubContainer?.scrollWidth || 0) - 1.5 * w) {
        epubContainer?.scrollTo({
          left: Math.round(Math.floor((leftX + 1.5 * w) / w) * w),
          behavior: 'smooth',
        });
      } else {
        this.hereBeDragonsWrapper(() => this.rendition?.next(), 'next');
      }
    }
  };

  initBook() {
    const { url, tocChanged, epubInitOptions } = this.props;
    if (this.book) {
      this.book.destroy();
    }
    if (!url) {
      console.warn('could not open book', url);
      return;
    }
    this.book = Epub(url, epubInitOptions);
    this.book.loaded.navigation
      .then(async ({ toc }) => {
        const locs = await loadLocations(this.id);
        if (locs) {
          this.book?.locations.load(locs);
          this.setState(
            {
              isLoaded: true,
              toc: toc,
            },
            () => {
              tocChanged?.(toc);

              this.initReader();
            }
          );
        } else {
          this.setState({ isLoaded: true }, () => {
            this.initReader();
          });
          //   this.props.setGeneratingLocations?.(true);
          this.book?.locations.generate(locationSize).then((locations: string[]) => {
            console.debug('finished generating locations');
            const locs = this.book?.locations?.save();
            if (locs) {
              saveLocations(this.id, locs);
            } else {
              console.error('no locations accessible after locations generated', this.book);
            }
            // this.props.setGeneratingLocations?.(false);
            this.setState(
              {
                toc: toc,
              },
              () => {
                tocChanged?.(toc);
              }
            );
          });
        }
        if (!locs && !this.book?.locations?.length()) {
          console.error('no locations for ', this.id, locs, this.book);
          throw new Error('no locations for ' + this.id);
        }
      })

      .catch((error) => {
        console.error('Error loading book:', error);
      });
  }

  initReader() {
    const { location, epubOptions, getRendition, settings, applySettings } = this.props;

    // Check if viewer reference is available
    if (this.viewerRef.current) {
      const node = this.viewerRef.current;

      // Check if book is available
      if (this.book) {
        const style = getComputedStyle(document.documentElement);

        // Calculate safe area insets
        const safeAreaInsetTop = parseInt(style.getPropertyValue('safe-area-inset-top'), 10) || 0;
        const safeAreaInsetBottom = parseInt(style.getPropertyValue('safe-area-inset-bottom'), 10) || 0;

        // Calculate desired dimensions
        const desiredHeight = window.innerHeight - safeAreaInsetBottom - safeAreaInsetTop;
        // const desiredWidth = window.innerWidth;

        const fontUrl = generateGoogleFontsUrl(googleFonts);

        // Initialize rendition with options
        const rendition = (this.book as any).renderTo(node, {
          manager: 'default',
          height: `${desiredHeight}px`,
          stylesheet: fontUrl,
          allowScriptedContent: true,
          spread: 'auto',
          minSpreadWidth: 900,
          flow: settings?.flow || 'paginated',
          gap: 0,
          style: { div: { whiteSpace: 'normal !important' } },
          ignoreClass: 'epubjs-ignore',
          ...epubOptions,
        });

        this.rendition = rendition;

        // // Disable existing stylesheets and add custom styles
        // rendition.hooks.content.register((contents: Contents) => {
        //   contents.addStylesheetRules([['*', ['all', 'initial', '!important']]], 'override');
        // });

        // Check and update metadata for language
        this.book.loaded.metadata.then((metadata) => {
          const bookLanguage = metadata.language;
          if (bookLanguage && this.props.meta && this.props.meta.languageCode !== bookLanguage && this.id) {
            updateBook({ ...this.props.meta, languageCode: bookLanguage });
            console.log('updated book language for book', this.id, ' to ', bookLanguage);
            // Update the saved BookMeta here if necessary
            // For example, you might call a function to save the updated meta
            // saveBookMeta(this.id, this.props.meta);
          }
        });

        // Register events and apply settings
        this.registerEvents();
        getRendition?.(rendition);

        // Wait for the iframe document to be ready before applying settings
        // applySettings?.(rendition).then(() => {
        //   const waitForIframe = () => {
        // if (this.getIframeDoc()) {
        applySettings?.(rendition);
        // } else {
        //   requestAnimationFrame(waitForIframe);
        // }
        //   };
        //   waitForIframe();
        // });
      }
    }
  }

  render() {
    const { isLoaded, contextMenu } = this.state;
    const { epubViewStyles = defaultStyles, settings } = this.props;

    return (
      <div style={epubViewStyles.viewHolder}>
        {(isLoaded && this.renderBook()) || <Loading theme={getTheme(this.props.user?.prefs?.theme)} />}
        {this.book && this.props.meta && (
          <ContextMenu
            x={contextMenu.x}
            y={contextMenu.y}
            open={contextMenu.visible}
            onSelectColor={this.handleSelectColor}
            onClose={(e: any) => {
              e.stopPropagation();
              this.setState({ contextMenu: { visible: false, x: 0, y: 0 } });
              this.clearSelection();
            }}
            text={this.state.selectText}
            getContextFunction={this.getSurroundingText}
            cfiRange={this.state.selectRange}
            book={this.book}
            progress={settings?.progress || 0}
            lastColor={settings?.lastColor || '#ffff00'}
            meta={this.props.meta}
            addNote={this.props?.addNote}
            context={this.state.selectContext}
            selection={this.state.selection}
          />
        )}
      </div>
    );
  }
}
