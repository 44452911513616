import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography, Skeleton, Stack, Box } from '@mui/material';
import type { BookMeta } from '../types/book';
import { getFile } from '../utils/indexedDB';
import { getPerson, getMinutesReadInBook, durationFormat, calculateEngagement } from '../utils/books';
import { useLongPress, type LongPressReactEvents, LongPressCallbackReason } from 'use-long-press';
import BookCoverContextMenu from './BookCoverContextMenu';
import { debounce } from '../utils/core';

interface BookCoverProps {
  book: BookMeta;
  onToggleArchive: () => void;
  onReplaceFile: () => void;
  onDeleteBook: () => void;
  userProgress?: { [userEmail: string]: any };
  isArchived: boolean;
  fileInputRef: React.RefObject<HTMLInputElement>;
}

interface BookCoverState {
  wordCount: number | null;
  coverUrl: string;
  minutesRead: number | undefined;
  engagement: number;
  isLoading: boolean;
}

const defaultCoverUrl = '/default_cover.webp';

const BookCover: React.FC<BookCoverProps> = ({
  book,
  onToggleArchive,
  userProgress,
  isArchived,
  onDeleteBook,
  onReplaceFile,
  fileInputRef,
}) => {
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [wordCount, setWordCount] = useState<number | null>(null);
  const [minutesRead, setMinutesRead] = useState<number | undefined>();
  const [engagement, setEngagement] = useState<number>(0);
  const prevIdRef = useRef(book.id);
  const prevWordCountRef = useRef(book.wordCount);
  const [isLoading, setIsLoading] = useState(true);

  const renderUserProgress = () => {
    if (!userProgress) {
      return (
        <Typography variant='body2' color='text.secondary'>
          Progress: {((book.settings?.progress || 0.0) * 100.0).toFixed(1)}%
        </Typography>
      );
    }

    return Object.keys(userProgress).map((email, index) => (
      <Typography key={`${book.hash}-${email}-${index}`} variant='body2' color='text.secondary'>
        {getPerson(email)?.given_name ?? 'Somebody'}: {(userProgress[email].progress * 100).toFixed(1)}%
        {userProgress[email].note_count ? ` (${userProgress[email].note_count})` : ''}
      </Typography>
    ));
  };

  const onLongPress = (event: LongPressReactEvents<Element>) => {
    event.preventDefault();
    if (event.target instanceof HTMLElement) {
      setAnchorEl(event.target);
    }
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  const bindLongPress = useLongPress(
    (event: LongPressReactEvents<Element>) => {
      onLongPress(event);
    },
    {
      onCancel: (event, meta) => {
        if (meta.reason === LongPressCallbackReason.CancelledByRelease) {
          //   onClick();
        } else {
          //   handleContextMenuClose();
        }
      },
      threshold: 500, // Milliseconds to trigger the long press
      captureEvent: false,
      cancelOnMovement: true,
    }
  );

  const debouncedLoadContent = useRef(
    debounce(async (book: BookMeta, setStates: (states: Partial<BookCoverState>) => void) => {
      const [wordCount, coverUrl, minutesRead, engagement] = await Promise.all([
        book.id && book.wordCount ? book.wordCount : null,
        loadCoverImage(book.id),
        book.id ? getMinutesReadInBook(book.id) : undefined,
        book.id && book.wordCount ? calculateEngagement(book) : 0,
      ]);

      setStates({
        wordCount,
        coverUrl,
        minutesRead,
        engagement,
        isLoading: false,
      });
    }, 300)
  ).current;

  const loadCoverImage = async (bookId: string): Promise<string> => {
    if (bookId) {
      try {
        const coverBlob = await getFile(`cover-${bookId}`);
        if (coverBlob && coverBlob.type.startsWith('image')) {
          return URL.createObjectURL(coverBlob);
        }
      } catch (error) {
        console.error('Error loading cover image:', error);
      }
    }
    return defaultCoverUrl;
  };

  const setStates = useCallback((states: Partial<BookCoverState>) => {
    Object.entries(states).forEach(([key, value]) => {
      switch (key) {
        case 'wordCount':
          setWordCount(value as number | null);
          break;
        case 'coverUrl':
          setCoverUrl(value as string);
          break;
        case 'minutesRead':
          setMinutesRead(value as number | undefined);
          break;
        case 'engagement':
          setEngagement(value as number);
          break;
        case 'isLoading':
          setIsLoading(value as boolean);
          break;
      }
    });
  }, []);

  useEffect(() => {
    debouncedLoadContent(book, setStates);

    return () => {
      debouncedLoadContent.cancel();
      if (coverUrl.startsWith('blob:')) {
        URL.revokeObjectURL(coverUrl);
      }
    };
  }, [book.id, book.wordCount, book.timestamp, debouncedLoadContent, setStates]);

  return isLoading ? (
    <Skeleton variant='rectangular' width={360} height={240} animation='wave' />
  ) : (
    <Card variant='outlined' {...bindLongPress()} sx={{ width: '360px', height: '240px' }}>
      <CardActionArea>
        <Stack direction='row'>
          <CardMedia
            component='img'
            image={coverUrl}
            alt={book.title}
            sx={{
              objectFit: 'contain',
              width: '160px',
              height: '240px',
              aspectRatio: '2/3',
              alignItems: 'center',
            }}
          />
          <BookCoverContextMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleContextMenuClose}
            onToggleArchive={onToggleArchive}
            onReplaceFile={() => {
              handleContextMenuClose();
              onReplaceFile();
            }}
            isArchived={isArchived}
            onDeleteBook={onDeleteBook}
          />

          <CardContent sx={{ padding: 0, width: '100%' }}>
            <Stack
              spacing={0}
              height={'100%'}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  ml: 1,
                  mr: 1,
                  mt: 0.5,
                  mb: 0,
                }}
              >
                {wordCount && (
                  <Typography color='#888' sx={{ fontSize: '0.9em' }}>
                    {Math.ceil(wordCount / 1000)}k words
                  </Typography>
                )}
                {minutesRead ? (
                  <Typography color='#888' sx={{ mr: 2, fontSize: '0.9em' }}>
                    read for <b>{durationFormat(minutesRead)}</b>
                  </Typography>
                ) : (
                  <Box /> // Empty box to maintain layout when minutesRead is not available
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  boxSizing: 'border-box',
                  ml: 1,
                  mr: 1,
                  mt: 0.5,
                  flexDirection: 'column',
                  px: 1,
                  py: 0.5,

                  width: 'stretch',
                  position: 'relative',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'currentColor',
                    opacity: 0.05,
                    mixBlendMode: 'difference',
                  },
                  border: '1px solid',
                  borderColor: 'currentColor',
                  borderRadius: 1,
                  '& > *': {
                    position: 'relative',
                    zIndex: 1,
                  },
                }}
              >
                {book.author && (
                  <Typography
                    // mt={2}
                    // mb={0}
                    // px={1}
                    sx={{
                      fontSize: '1em',
                      boxSizing: 'border-box',
                      maxWidth: '200px',
                      textAlign: 'center',
                      width: '100%',
                      opacity: 0.8,
                      fontWeight: 'light',
                    }}
                  >
                    {book.author}
                  </Typography>
                )}

                <Typography
                  //   mb={2}
                  //   px={1}
                  sx={{
                    boxSizing: 'border-box',
                    fontSize: '1.4em',
                    maxWidth: '200px',
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  {book.title}
                </Typography>
              </Box>
              {engagement > 0 && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', pr: 2, mt: 1 }}>
                  <Typography sx={{ pr: 2, color: '#bbb' }}>
                    <b>{(engagement * 100).toFixed(0)}%</b> focus
                  </Typography>
                </Box>
              )}
              <Box flexGrow={1} />

              <Box
                sx={{
                  pl: 2,
                  mb: 0,
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {renderUserProgress()}
              </Box>
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

export default React.memo(BookCover);
