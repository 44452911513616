import React, { useRef, useEffect } from 'react';
import { Box } from '@mui/material';
import type { StatusBarBoxInfo } from '../types/book';

interface Props {
  content: string;
  sx?: any;
  onClick?: (e?: any) => void;
  onHold: (e: React.MouseEvent<HTMLElement>) => void;
  boxInfo: StatusBarBoxInfo;
}

const StatusBarBox: React.FC<Props> = ({ content, sx, onClick, onHold, boxInfo }) => {
  const holdTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (holdTimeoutRef.current) {
        clearTimeout(holdTimeoutRef.current);
      }
    };
  }, []);

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    holdTimeoutRef.current = setTimeout(() => {
      onHold(e as any);
    }, 250);
  };

  const handleTouchEnd = () => {
    if (holdTimeoutRef.current) {
      clearTimeout(holdTimeoutRef.current);
    }
  };

  return (
    <Box
      ref={boxRef}
      sx={{
        px: 0.5,
        minWidth: '48px',
        userSelect: 'none',
        whiteSpace: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word',
        ...sx,
      }}
      onClick={onClick}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      {content || '\u00A0'}
    </Box>
  );
};

export default StatusBarBox;
