import { useState, useCallback, useEffect } from 'react';
import { IconButton, Tooltip, Stack, Divider, Dialog } from '@mui/material';
import {
  AutoStories as AutoStoriesIcon,
  AutoAwesome as AutoAwesomeIcon,
  RecentActors as RecentActorsIcon,
  Circle as CircleIcon,
  Translate as TranslateIcon,
  Grass as GrassIcon,
} from '@mui/icons-material';
import { DictionaryView } from './DictionaryView';
import Geepers from './Geepers';
import { WiktionaryView } from './WiktionaryView';
import type { Book } from 'epubjs';
import type { BookMeta, NoteCommentGroup } from '../types/book';
import { useOnlineStore } from '../store/onlineStore';

interface ContextMenuProps {
  x: number;
  y: number;
  open: boolean;
  onClose: (e: MouseEvent) => void;
  onSelectColor: (color: string, text: string, cfiRange: string) => void;
  text: string;
  context: string;
  cfiRange: string;
  book: Book;
  progress: number;
  lastColor?: string;
  meta: BookMeta;
  addNote?: (cfiRange: string, color: string, text: string, comments?: NoteCommentGroup) => void;
  getContextFunction: (selection: Selection | null) => string;
  selection: Selection | null;
}

export default function ContextMenu({
  x,
  y,
  onSelectColor,
  open,
  onClose,
  text,
  context,
  cfiRange,
  book,
  progress,
  lastColor,
  meta,
  addNote,
  getContextFunction,
  selection,
}: ContextMenuProps) {
  const [dictionaryOpen, setDictionaryOpen] = useState(false);
  const [geepersOpen, setGeepersOpen] = useState(false);
  const [geepersCommand, setGeepersCommand] = useState('magic');
  const buttonSize = 40;
  const buttonMargin = 0.5;
  const [wiktionaryOpen, setWiktionaryOpen] = useState(false);
  const [language, setLanguage] = useState(meta.languageCode || 'en');

  const handleColorSelect = useCallback(
    (e: MouseEvent) => {
      onSelectColor(lastColor || '#ffff00', text, cfiRange);
      onClose(e as MouseEvent);
    },
    [text, cfiRange, lastColor]
  );

  const handleDictionaryOpen = () => {
    console.log('handleDictionaryOpen', language, text);
    if (text) {
      if (language.toLowerCase().startsWith('en')) {
        setDictionaryOpen(true);
      } else {
        console.debug('setWiktionaryOpen');
        setWiktionaryOpen(true);
      }
    }
  };

  const handleDictionaryClose = () => {
    setDictionaryOpen(false);
  };

  const handleWiktionaryClose = () => {
    setWiktionaryOpen(false);
  };

  const handleGeepersOpen = (command = 'magic') => {
    setGeepersCommand(command);
    setGeepersOpen(true);
  };

  const handleGeepersClose = () => {
    setGeepersOpen(false);
  };

  useEffect(() => {
    if (meta.languageCode) {
      setLanguage(meta.languageCode.slice(0, 2).toLowerCase() || 'en');
    }
  }, [meta.languageCode]);

  const isOnline = useOnlineStore((state) => state.isOnline);

  return (
    <>
      {/* <Menu
        id="context-menu"
        disableAutoFocus={true}
        disableEnforceFocus={false}
        autoFocus={false}
        open={open}
        variant={"menu"}
        onClose={onClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: y, left: x }}
        MenuListProps={{
          "aria-labelledby": "lock-button",
          role: "listbox",
          disablePadding: true,
        }} */}
      <Dialog
        hideBackdrop
        id='context-menu'
        open={open}
        onClose={onClose}
        disableScrollLock={true}
        disableEscapeKeyDown={true}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        PaperProps={{
          style: {
            position: 'absolute',
            top: y,
            // left: x,
            width: 'auto',
            height: 'auto',
            pointerEvents: 'auto',
          },
        }}
        // placement="bottom"
        disablePortal={false}
        // modifiers={[
        //   {
        //     name: "flip",
        //     enabled: false,
        //     options: {
        //       altBoundary: false,
        //       rootBoundary: "viewport",
        //       padding: 8,
        //     },
        //   },
        //   {
        //     name: "preventOverflow",
        //     enabled: false,
        //     options: {
        //       altAxis: false,
        //       altBoundary: false,
        //       tether: false,
        //       rootBoundary: "viewport",
        //       padding: 8,
        //     },
        //   },
        // ]}
        style={{
          pointerEvents: 'none',
          transition: 'all 1s ease-in-out',
          opacity: 1,
          width: open ? 'auto' : 0,
          height: open ? 'auto' : 0,
        }}
      >
        <Stack direction='row' spacing={0} divider={<Divider orientation='vertical' flexItem sx={{ mx: 0 }} />}>
          <Tooltip title='Highlight or add note'>
            <IconButton onClick={(e: any) => handleColorSelect(e)} aria-label='highlight'>
              <CircleIcon
                style={{
                  margin: buttonMargin,
                  color: lastColor || '#ffff00',
                  fontSize: buttonSize,
                }}
              />
            </IconButton>
          </Tooltip>
          {isOnline && (
            <Tooltip title='Lookup in Dictionary'>
              <IconButton onClick={handleDictionaryOpen} aria-label='dictionary'>
                <AutoStoriesIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {!meta.languageCode?.toLowerCase().includes('en') && isOnline && (
            <Tooltip title='Have AI translate'>
              <IconButton onClick={() => handleGeepersOpen('translate')} aria-label='translate'>
                <TranslateIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && (
            <Tooltip title='Have AI translate, explain, and/or define'>
              <IconButton
                onClick={() =>
                  handleGeepersOpen(
                    language.startsWith('fr') ? 'french' : language.startsWith('eo') ? 'esperanto' : 'magic'
                  )
                }
                aria-label='magic'
              >
                <AutoAwesomeIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && (
            <Tooltip title='Have AI compose a haiku'>
              <IconButton onClick={() => handleGeepersOpen('haiku')} aria-label='haiku'>
                <GrassIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
          {isOnline && (
            <Tooltip title='Have AI summarize or explain a character or other plot element'>
              <IconButton onClick={() => handleGeepersOpen('deepdive')} aria-label='deepdive'>
                <RecentActorsIcon style={{ fontSize: buttonSize, margin: buttonMargin }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </Dialog>
      <DictionaryView open={dictionaryOpen} onClose={handleDictionaryClose} word={text} />
      <WiktionaryView open={wiktionaryOpen} onClose={handleWiktionaryClose} word={text} languageCode={language} />
      <Geepers
        open={geepersOpen}
        onClose={handleGeepersClose}
        text={text}
        context={context}
        command={geepersCommand}
        book={book}
        progress={progress}
        meta={meta}
        cfiRange={cfiRange}
        addNote={addNote}
        getContextFunction={getContextFunction}
        selection={selection}
      />
    </>
  );
}
