import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  Stack,
  CircularProgress,
  Divider,
} from '@mui/material';
import { Close as CloseIcon, Share as ShareIcon, NoteAdd as NoteAddIcon } from '@mui/icons-material';
import { fetchGeepersData, scanBookForContext } from '../utils/books';
import type { Book } from 'epubjs';
import type { BookMeta, NoteCommentGroup } from '../types/book';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { aiUser } from '../config';

interface GeepersProps {
  open: boolean;
  onClose: () => void;
  text: string;
  context: string;
  command: string;
  book: Book;
  progress: number;
  meta: BookMeta;
  cfiRange: string;
  addNote?: (cfiRange: string, color: string, text: string, comments?: NoteCommentGroup) => void;
  getContextFunction: (selection: Selection | null) => string;
  selection: Selection | null;
}

const Geepers = ({
  open,
  onClose,
  text,
  context,
  command,
  book,
  progress,
  meta,
  cfiRange,
  addNote,
  getContextFunction,
  selection,
}: GeepersProps) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      let myContext;
      if (command === 'deepdive') {
        const results = await scanBookForContext(book, text, progress);
        if (results.length > 0) {
          myContext = results.filter((v, i, a) => a.indexOf(v) === i).join('\n\n…\n\n');
        } else {
          console.log('No hits');
        }
      } else {
        myContext = getContextFunction(selection) || '';
      }
      try {
        const result = await fetchGeepersData({
          command: command,
          text: text,
          context: myContext || '',
          lang: meta.languageCode,
        });
        setResponse(result);
      } catch (error) {
        setResponse("Outlook cloudy. Ask again later.\n(Probably you're not logged in, or Trevor's server is down.)");
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      setLoading(true);
      fetchData();
    }
  }, [open, text, context, meta.languageCode, command, selection]);

  const handleCreateNote = () => {
    if (text && response && addNote) {
      const commentId = crypto.randomUUID();
      addNote(cfiRange, aiUser.color || '#0f0', text, {
        [commentId]: {
          id: commentId,
          text: response,
          owner: aiUser.email,
          timestamp: new Date().toISOString(),
          reactions: {},
        },
      });

      console.debug('added AI note');
      onClose();
    }
  };

  const handleShare = () => {
    const share = async () => {
      if (text && response) {
        // const innerText = dialogContentRef.current.innerText || "";
        // let innerHTML = dialogContentRef.current.innerHTML || "";
        // if (innerHTML) {
        //   innerHTML = innerHTML
        //     .replace(/ class="(.*?)"/g, "")
        //     .replace(/ style="(.*?)"/g, "");
        // }
        await navigator.share({
          title: text,
          text: response,
        });
      }
    };
    share();
  };

  return (
    <Dialog className='notranslate' open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems={text.length > 30 ? 'flex-start' : 'center'}>
          <Typography
            sx={{
              fontSize: text.length > 30 ? '75%' : '100%',
              color: command === 'language' ? '#acf' : '#ffb703',
              flexGrow: 1,
            }}
          >
            {text.slice(0, 100) + (text.length > 100 ? '\u2026' : '')}
          </Typography>
          <Stack direction='row' spacing={1}>
            {response && (
              <>
                <IconButton onClick={handleCreateNote} aria-label='create note' edge='end'>
                  <NoteAddIcon />
                </IconButton>
                <IconButton onClick={handleShare} aria-label='share' edge='end'>
                  <ShareIcon />
                </IconButton>
              </>
            )}
            <IconButton aria-label='close' onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? (
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        ) : (
          <Markdown className='markdown' remarkPlugins={[remarkGfm]} rehypePlugins={[rehypeRaw]}>
            {response}
          </Markdown>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default Geepers;
