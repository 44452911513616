import React, { useEffect, useState } from 'react';
import { List, ListItem, Avatar, Stack, Typography, Divider, Box, Switch, Chip, Tooltip } from '@mui/material';
import { Note as NoteIcon } from '@mui/icons-material';
import { getBook, getPerson, getCurrentUser } from '../utils/books';
import type { BookSettings, Note, NotesGroup, BookMeta } from '../types/book';
import { EpubCFI } from 'epubjs';
import remarkGfm from 'remark-gfm';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import MarkdownImage from './MarkdownImage';

const getLatestTimestamp = (note: Note): string => {
  const commentTimestamps = note.comments
    ? Object.values(note.comments)
        .filter((c) => !c.deleted)
        .map((c) => c.timestamp)
    : [];
  return [note.timestamp, ...commentTimestamps].sort().pop() || note.timestamp;
};

const getLatestCommenter = (note: Note): string | undefined => {
  if (!note.comments) return undefined;
  const latestComment = Object.values(note.comments)
    .filter((c) => !c.deleted)
    .sort((a, b) => b.timestamp.localeCompare(a.timestamp))[0];
  return latestComment?.owner;
};

const NotesComponent: React.FC<{
  bookId: string;
  settings: BookSettings;
  updateSettings: (settings: BookSettings, progress?: number) => void;
  setLocation: (value: string) => void;
  closeDrawer: () => void;
}> = ({ bookId, setLocation, closeDrawer, settings, updateSettings }) => {
  const [notes, setNotes] = useState<Note[]>([]);
  const [bookMeta, setBookMeta] = useState<BookMeta>();
  const [hidden, setHidden] = useState(0);
  const [sortByTime, setSortByTime] = useState<boolean>(true);
  const [selectedComment, setSelectedComment] = useState<string | null>(null);

  useEffect(() => {
    const fetchNotes = async () => {
      if (!bookMeta?.hash) {
        console.error('no book hash', bookId);
        return;
      }
      //   const annotations: NotesGroup = book?.notes ? { "": book.notes } : {};

      // Fetch external notes from server
      //   const response = await fetch(`/api/notes/${bookMeta.hash}`);
      //   const all: NotesGroup = await response.json();
      const all: NotesGroup = { ...bookMeta.notes };

      //   //   const ann: NotesGroup = all ? { ...all } : {};
      //   let r: Note[] = [];
      //   // Merge annotations into ann, skipping notes with ids that already exist in allNotes
      //   Object.keys(all).forEach((email) => {
      //     all[email].forEach((note: Note) => {
      //       // Check if this note id already exists in ann
      //       const noteExists = Object.values(r)
      //         .flat()
      //         .some((existingNote) => existingNote.id === note.id);

      //       if (!noteExists) {
      //         r.push({ owner: email, ...note }); // Add the new note
      //       }
      //     });
      //   });

      let r: NotesGroup = {};
      if (bookMeta.settings.location) {
        if (settings.showNotes) {
          setHidden(0);
          r = all;
        } else {
          const oldLength = Object.keys(all).length;
          r = Object.values(all)
            .filter((note) => EpubCFI.prototype.compare(note.cfiRange, bookMeta.settings.location!) !== 1)
            .reduce((acc, note) => {
              acc[note.id] = note;
              return acc;
            }, {} as NotesGroup);
          setHidden(oldLength - Object.keys(r).length);
        }
      }
      setNotes(
        sortByTime
          ? Object.values(r).sort((a, b) => getLatestTimestamp(b).localeCompare(getLatestTimestamp(a)))
          : Object.values(r).sort((b, a) => EpubCFI.prototype.compare(a.cfiRange, b.cfiRange))
      );
    };

    if (bookId && bookMeta?.notes) {
      fetchNotes();
    }
  }, [bookMeta, bookId, sortByTime, settings]);

  useEffect(() => {
    setBookMeta(getBook(bookId));
  }, [bookId]);

  const handleNoteSelect = (noteCfiRange: string) => {
    // Navigate to the note's location in the reader
    // navigate(`/read/${bookId}?cfi=${noteCfiRange}`);
    let target = noteCfiRange;
    if (target.includes('epubcfi')) {
      target = noteCfiRange.split(',').slice(0, 2).join('');
    }

    console.log('handleNoteSelect:', noteCfiRange, 'changed to', target);
    setLocation(noteCfiRange);
    closeDrawer();
  };

  useEffect(() => {
    console.log('notes:', notes);
  }, [notes]);

  return (
    <Box sx={{ bgcolor: 'background.paper' }}>
      <Stack direction='row' spacing={0} alignItems='center' justifyContent='flex-end' padding='0.5em 1.5em 0em 0em'>
        <Typography sx={{ fontVariant: 'small-caps', opacity: 0.5 }}>Hide</Typography>
        <Switch
          defaultChecked={settings.showNotes}
          onChange={() => updateSettings({ showNotes: !settings.showNotes })}
          inputProps={{ 'aria-label': 'show notes switch' }}
        />
        <Typography sx={{ fontVariant: 'small-caps', opacity: 0.5 }}>Show</Typography>
      </Stack>

      <Stack direction='row' spacing={0} alignItems='center' justifyContent='flex-end' padding='0.5em 1.5em 0em 0em'>
        <Typography sx={{ fontVariant: 'small-caps', opacity: 0.5 }}>Space</Typography>
        <Switch
          defaultChecked
          onChange={() => setSortByTime(!sortByTime)}
          inputProps={{ 'aria-label': 'sort switch' }}
        />
        <Typography sx={{ fontVariant: 'small-caps', opacity: 0.5 }}>Time</Typography>
      </Stack>
      {hidden > 0 && (
        <Typography
          sx={{
            m: '0em',
            paddingTop: '1.5em',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: 'large',
            color: 'text.secondary',
          }}
        >
          {`${hidden} note${hidden > 1 ? 's' : ''} hidden...`}
        </Typography>
      )}
      <List sx={{ m: 1, p: 0, pt: 1 }}>
        {notes
          .filter((n) => !n.deleted)
          .map((note, index) => (
            <React.Fragment key={note.id}>
              <ListItem
                alignItems='flex-start'
                onClick={() => handleNoteSelect(note.cfiRange)}
                key={index}
                sx={{ p: 0, py: 1, m: 0 }}
              >
                <Stack direction='row' spacing={2} alignItems='flex-start' sx={{ width: '100%' }}>
                  <Stack alignItems={'center'} alignSelf={'top'} sx={{ ml: 0.5, mr: 1.5 }}>
                    <Avatar
                      sx={{ width: 40, height: 40 }}
                      src={note.owner === '' ? getCurrentUser()?.picture : getPerson(note?.owner || '')?.picture}
                    >
                      <NoteIcon />
                    </Avatar>
                    {note.progress && (
                      <Typography
                        sx={{
                          display: 'inline',
                          opacity: 0.6,
                          fontSize: 'small',
                          m: '4px auto',
                        }}
                        component='span'
                        variant='body2'
                        color='text.primary'
                      >
                        {(note.progress * 100).toFixed(1)}%
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction='column' spacing={0.5} sx={{ flexGrow: 1 }}>
                    <Typography variant='body1' component='div'>
                      <Markdown className='notecomponent'>{note.text}</Markdown>
                    </Typography>
                    {note.comments && Object.values(note.comments).filter((c) => !c.deleted).length > 0 && (
                      <Stack direction='row' spacing={0.5} sx={{ mt: 1 }}>
                        {Object.values(note.comments)
                          .filter((c) => !c.deleted)
                          .sort((a, b) => a.timestamp.localeCompare(b.timestamp))
                          .map((comment) => (
                            <Tooltip key={comment.id} title={`${getPerson(comment.owner)?.name || 'Unknown'}`}>
                              <Chip
                                avatar={<Avatar src={getPerson(comment.owner)?.picture} />}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedComment(selectedComment === comment.id ? null : comment.id);
                                }}
                                size='small'
                                sx={{
                                  height: 24,
                                  '& .MuiChip-avatar': {
                                    width: 20,
                                    height: 20,
                                  },
                                }}
                              />
                            </Tooltip>
                          ))}
                      </Stack>
                    )}
                    {selectedComment &&
                      note.comments &&
                      note.comments[selectedComment] &&
                      !note.comments[selectedComment].deleted && (
                        <Box
                          sx={{
                            mt: 1,
                            p: 1,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                          }}
                        >
                          <Typography variant='body2' component='div'>
                            <Markdown
                              className='markdown notebox'
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                              components={{
                                img: (props) => <MarkdownImage {...props} />,
                              }}
                            >
                              {note.comments[selectedComment].text}
                            </Markdown>
                          </Typography>
                          <Typography variant='caption' color='text.secondary'>
                            {new Intl.DateTimeFormat('en-US', {
                              month: 'short',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: 'numeric',
                            }).format(new Date(note.comments[selectedComment].timestamp))}
                          </Typography>
                        </Box>
                      )}
                  </Stack>
                </Stack>
              </ListItem>
              {index < notes.length - 1 && <Divider variant='inset' component='li' />}
            </React.Fragment>
          ))}
      </List>
    </Box>
  );
};

export default NotesComponent;
