import { CircularProgress } from '@mui/material';

export const Loading: React.FC<{ theme?: string; text?: string }> = ({ theme, text }) => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: (theme && (theme === 'dark' ? 'white' : 'black')) || 'gray',
        zIndex: 1000,
      }}
    >
      <CircularProgress color='inherit' style={{ marginBottom: '20px' }} />
      {text && <div>{text}</div>}
    </div>
  );
};

export default Loading;
