import React from 'react';
import { Menu, MenuItem } from '@mui/material';

interface BookCoverContextMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onToggleArchive: () => void;
  onReplaceFile: () => void;
  onDeleteBook: () => void;
  isArchived: boolean;
}

const BookCoverContextMenu: React.FC<BookCoverContextMenuProps> = ({
  anchorEl,
  open,
  onClose,
  onToggleArchive,
  onReplaceFile,
  onDeleteBook,
  isArchived,
}) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'long-press-menu',
      }}
    >
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onToggleArchive();
          onClose();
        }}
      >
        {isArchived ? 'Unarchive' : 'Archive'}
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onReplaceFile();
          onClose();
        }}
      >
        Replace ePub file
      </MenuItem>
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          onDeleteBook();
          onClose();
        }}
      >
        Delete book
      </MenuItem>
    </Menu>
  );
};

export default BookCoverContextMenu;
