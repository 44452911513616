import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { addBookFromFile } from '../utils/books';
import Loading from './Loading';
import type { UserInfo } from '../types/book';

/**
 * Component to handle EPUB file shares.
 * It checks for user authentication before processing shared files.
 */
const HandleShare: React.FC<{
  setSnackbarMessage: (message: { text: string; duration: number }) => void;
  user: UserInfo | null | undefined;
}> = ({ setSnackbarMessage, user }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleShare = async () => {
      if ('launchQueue' in window && (window as any).launchQueue.setConsumer) {
        (window as any).launchQueue.setConsumer(async (launchParams: { files: File[] }) => {
          if (!launchParams.files.length) return;

          if (!user) {
            setSnackbarMessage({ text: 'Please log in to add shared books.', duration: 3000 });
            navigate('/');
            return;
          }

          try {
            const epubFiles = launchParams.files.filter(
              (file) => file.type === 'application/epub+zip' || file.name.endsWith('.epub')
            );

            if (epubFiles.length === 0) {
              setSnackbarMessage({ text: 'No valid EPUB files found.', duration: 3000 });
              return;
            }

            await Promise.all(epubFiles.map((file) => addBookFromFile(file)));
            setSnackbarMessage({ text: 'Books added successfully!', duration: 3000 });
            navigate('/'); // Redirect to BookList
          } catch (error) {
            console.error('Error adding shared books:', error);
            setSnackbarMessage({ text: 'Failed to add shared books.', duration: 3000 });
          }
        });
      } else {
        setSnackbarMessage({
          text: 'Sorry, this only works on Android, and when installed with Chrome.',
          duration: 3000,
        });
        console.error('launchQueue is not supported in this browser.');
      }
    };

    handleShare();
  }, [navigate, user, setSnackbarMessage]);

  return <Loading text='Adding books...' />;
};

export default HandleShare;
